import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { useState } from 'react';
import { InvoiceSummary } from '../../../apis/invoices';
import PageLoading from '../../../components/PageLoading';
import { isSuccess } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { getPaymentAmount } from '../../../util/invoiceUtils';
import MakePayment from './MakePayment';

type Props = {
    invoice: InvoiceSummary;
};

export default function AdjustmentPaymentRequiredAlert({ invoice }: Readonly<Props>) {
    const [openMakePayment, setOpenMakePayment] = useState(false);
    const paymentAmount = getPaymentAmount(invoice);

    const { paymentMethodState, availablePaymentMethodsState } = useAppSelector(
        (state) => state.persistedInvoiceReducer
    );

    if (!isSuccess(paymentMethodState) || !isSuccess(availablePaymentMethodsState)) {
        return <PageLoading />;
    }

    return (
        <Alert severity='info' variant='outlined' sx={{ py: 2 }}>
            <AlertTitle>Payment required</AlertTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <span>
                    Your policy has been adjusted. Make sure your payment method is up to date, and click pay now to pay
                    the due amount of {currencyFormatter.format(paymentAmount)}
                </span>
                <Button
                    sx={{ width: '228px' }}
                    color='info'
                    variant='contained'
                    onClick={() => setOpenMakePayment(true)}
                >
                    Pay {currencyFormatter.format(paymentAmount)} now
                </Button>
                <MakePayment
                    open={openMakePayment}
                    setClosed={() => setOpenMakePayment(false)}
                    title='Pay outstanding balance'
                    description='Due now'
                />
            </Box>
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
