import { ArrowForwardIos } from '@mui/icons-material';
import { Box, ButtonBase, Chip, styled, Typography } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
    CustomCheckoutType,
    InvoiceStatus,
    InvoiceSummary,
    MigrationStatus,
    PaymentFrequency,
} from '../../apis/invoices';
import { AppPortalType } from '../../apis/seller';
import LoanWithIQumulate from '../../components/LoanWithIQumulate';
import NextPaymentDetails from '../../components/NextPaymentDetails';
import PaidUntil from '../../components/PaidUntil';
import PolicyDescription from '../../components/PolicyDescription';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setInvoice } from '../../store/reducer/InvoiceReducer';
import { RootState } from '../../store/Store';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT, isInFuture } from '../../util/dateUtils';
import OverdueTile from './OverdueTile';
import PaymentRequiredTile from './PaymentRequiredTile';

type Props = {
    invoice: InvoiceSummary;
};

export default ({ invoice }: Props) => {
    const { uiConfig } = useAppSelector((root: RootState) => root.persistedSellerReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const policyCount = invoice.portfolio.policies.length;
    const mainPolicy = invoice.portfolio.policies[0];
    const inProgress = invoice.status === InvoiceStatus.IN_PROGRESS;
    const loanWithIQumulate = invoice.customCheckoutDetail?.customCheckoutType === CustomCheckoutType.IQUMULATE;

    const selectInvoice = () => {
        if (!inProgress) {
            dispatch(setInvoice(invoice));
            navigate(`/invoices/${invoice.uuid}`);
            return;
        }

        if (uiConfig?.origins?.[AppPortalType.CHECKOUT]) {
            window.location.href = uiConfig?.origins?.[AppPortalType.CHECKOUT] + '/' + invoice.uuid;
        } else {
            window.location.href = process.env.REACT_APP_CHECKOUT_HOST + '/' + invoice.uuid;
        }
    };

    const getSummary = () => {
        if (loanWithIQumulate) {
            return <LoanWithIQumulate invoice={invoice} />;
        }
        if (inProgress) {
            return <PayNow />;
        }
        if (
            invoice.status === InvoiceStatus.ACTIVE_PENDING_ACCEPTANCE ||
            invoice.status === InvoiceStatus.PENDING_ADJUSTMENT_PAYMENT
        ) {
            return (
                <Chip
                    sx={{ alignSelf: 'flex-start', cursor: 'inherit' }}
                    color='info'
                    variant='outlined'
                    label='Payment required'
                />
            );
        }
        if (invoice.status === InvoiceStatus.PENDING_CANCELLATION) {
            return <WarningChip label='Pending cancellation' />;
        }
        if (invoice.status === InvoiceStatus.CANCELLED) {
            return <WarningChip label='Inactive' />;
        }
        if (invoice.status === InvoiceStatus.PARTIALLY_PAID) {
            return <PaymentRequiredTile />;
        }
        if (invoice.status === InvoiceStatus.OVERDUE) {
            return <OverdueTile invoice={invoice} />;
        }
        if (invoice.nextPayment != null && invoice.paymentFrequency !== PaymentFrequency.IN_FULL) {
            return <NextPaymentDetails invoice={invoice} />;
        }
        if (invoice.migrationStatus === MigrationStatus.PARTIAL) {
            return null;
        }
        return <PaidUntil invoice={invoice} />;
    };

    return (
        <CardButton onClick={selectInvoice} data-testid='invoice-card-button'>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <PolicyDescription policy={mainPolicy} productLogoUrl={invoice.productLogoUrl} />
                <Box fontSize={14}>
                    <ArrowForwardIos color='primary' fontSize='inherit' />
                </Box>
            </Box>
            {policyCount > 1 && <Typography variant='body2'>+ {policyCount - 1} more</Typography>}
            {isInFuture(invoice.cancellationDate) && invoice.status != InvoiceStatus.CANCELLED && (
                <WarningChip
                    label={`Cancels on ${moment(invoice.cancellationDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}`}
                />
            )}
            {getSummary()}
        </CardButton>
    );
};

const WarningChip = ({ label }: { label: string }) => {
    return (
        <Chip sx={{ alignSelf: 'flex-start', cursor: 'inherit' }} color='warning' variant='outlined' label={label} />
    );
};

const CardButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    border: 'solid',
    borderColor: grey['300'],
    borderWidth: '1px',
    borderRadius: theme.spacing(1),
    textAlign: 'left',
    background: common.white,
    alignItems: 'flex-start',
}));

const PayNow = () => {
    return <Chip sx={{ alignSelf: 'flex-start', cursor: 'pointer' }} color='warning' label='Pay now' />;
};
