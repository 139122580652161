import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { ConsolidatedPayment } from '../../apis/invoices';
import { DATE_FRIENDLY } from '../../util/dateUtils';

type Props = {
    open: boolean;
    handleClose: () => void;
    futurePayments: ConsolidatedPayment[];
};

export default ({ open, handleClose, futurePayments }: Props) => {
    return (
        <Dialog open={open} onClose={handleClose} scroll='paper' aria-labelledby='alert-dialog-title'>
            <DialogTitle id='alert-dialog-title'>Future billing payments</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pr: { sm: '100px' } }}>
                                <Typography variant='subtitle2' component='p'>
                                    Payment date
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography variant='subtitle2' component='p'>
                                    Amount
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {futurePayments.map((payment) => {
                            const feeAmount = payment.feeAmount ?? 0;
                            const paidAmount = payment.paidAmount ?? 0;
                            const waivedAmount = payment.waivedAmount ?? 0;
                            const totalAmount = payment.amount + feeAmount - paidAmount - waivedAmount;
                            return (
                                <TableRow key={payment.identifier}>
                                    <TableCell sx={{ pr: { sm: '100px' } }}>
                                        <Typography variant='caption'>
                                            {moment(payment.dueDate).format(DATE_FRIENDLY)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography variant='caption'>
                                            {currencyFormatter.format(totalAmount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
